import React, { useState, useEffect, useRef } from 'react';
import {Link, useNavigate, useParams, useLocation} from "react-router-dom";
import { Helmet } from 'react-helmet';
import helpers from './../utils';
import PropTypes from 'prop-types'

// Images Common File
import Img from '../Components/Img';


// Design File
import Horizontalbanner from "../Components/Adsection/Horizontalbanner";
import Squarebanner from "../Components/Adsection/Squarebanner";
import Verticalbanner from "../Components/Adsection/Verticalbanner";
// import Fbwidgets from "../Components/Widgets/Fbwidgets";
import Socialicon from '../Components/Socialicon';
import Storelink from '../Components/Storelink'


function Category(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const page_name_get = ((location.pathname.indexOf('search') > -1) ? 'search' : ((location.pathname.indexOf('category') > -1) ? 'category' : ''));
    const {name,search_name} = useParams();

    const [category, setCategory] = useState([]);
    const [sections, setSections] = useState([]);
    const [page, setPage] = useState('');
    const [loading, setLoading] = useState(false);
    const [lastNewsId, setLastNewsId] = useState('');
    const sendRequestRef = useRef(true);

    useEffect(() => {
        setSections([]);
        setLoading(true);
        const categoryData = async () => {
            try {
                const nav_response = await helpers.apiCall(helpers.API_BASE_URL+'get-categories',{category_name:name});
                if (nav_response != '') {
                    setCategory(nav_response?.category_detail);
                    setLastNewsId('');
                    setSections([]);
                    sendRequestRef.current = true;
                    setPage(1); 
                }
            } catch (error) {
                setCategory([]);
                setLastNewsId('');
                setSections([]);
                sendRequestRef.current = true;
                setPage(1);
                navigate('/');
                console.error('Error fetching data:', error);
            }
        }
        if(location.pathname.indexOf('category') > -1)
        {
            categoryData();
        }
        if(location.pathname.indexOf('search') > -1)
        {
            setSections([]);
            sendRequestRef.current = true;
            setPage(1);
        }
    },[name,search_name]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if(sendRequestRef.current === true) {
                    sendRequestRef.current = false;
                    setLoading(true);
                    // Simulating API call, replace with your actual API endpoint
                    if(location.pathname.indexOf('category') > -1)
                    {
                        const response = await helpers.apiCall(helpers.API_BASE_URL+'get-more-news',{last_news_id: lastNewsId,cat_id: category?.id,tag_id: 0,page: page,type: 'category',device_type:'d'});
                        if (response != '') {
                            if(response.news.length > 0)
                            {
                                setSections((prevSections) => [...prevSections, response.news]);
                                setLastNewsId((response.news.length > 0) ? response.news[response.news.length - 1]?.news_id : '');
                                setPage((prevPage) => prevPage + 1);
                                sendRequestRef.current = true;
                            }
                            else
                            {
                                sendRequestRef.current = false;   
                            }
                        }
                        else
                        {
                            sendRequestRef.current = true;
                        }
                    }

                    if(location.pathname.indexOf('search') > -1)
                    {
                        const response_search_get = await helpers.apiCall(helpers.API_BASE_URL+'get-search-news',{page: page,keyword: search_name});
                        if (response_search_get != '') {
                            if(response_search_get.news.length > 0)
                            {
                                setSections((prevSections) => [...prevSections, response_search_get.news]);
                                setLastNewsId('');
                                setPage((prevPage) => prevPage + 1);
                                sendRequestRef.current = true;
                            }
                            else
                            {
                                sendRequestRef.current = false;   
                            }
                        }
                        else
                        {
                            sendRequestRef.current = true;
                        }
                    }
                }
            } catch (error) {
            console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        const handleScroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 200 && !loading) {
                if(page != '')
                {
                    fetchData();
                }
            }
        };
        if(location.pathname.indexOf('category') > -1)
        {
            if(category && Object.keys(category).length > 0 && (page == 1 || page == 2))
            {
                if(page == 1)
                {
                    setLastNewsId('');
                    setSections([]);
                }
                if(page != '')
                {
                    fetchData();
                }
            }
        }

        if(location.pathname.indexOf('search') > -1)
        {
            if(page == 1)
            {                
                setLastNewsId('');
                setSections([]);
            }
            if(page != '')
            {
                fetchData();
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
      }, [loading, page, category]);

    useEffect(() => {},[sections]);

    const renderNews = (itemdata,index) => {
        return (
            (Object.keys(itemdata).length > 0) && (
                <div className="cat-news-list" key={index}>
                    {
                        itemdata.map((item,index2) => (
                            <div className="cnl-item" key={index2} >
                                <Link to={((item.news_link2) ? item.news_link2 : 'javascript:void(0);' )} className="cnl-item-img">
                                    <img className="cnl-item-img-bg" src={item.image_sm} alt="" loading="lazy" />
                                </Link>
                                <Link className="cnl-item-text" to={((item.news_link2) ? item.news_link2 : 'javascript:void(0);' )}>
                                    <div className="cnl-item-text-hed">{item.highlight_title+' '+item.title}</div>
                                </Link>
                            </div>
                        ))
                    }
                    <div className='bnr-da-cat'>
                        <Horizontalbanner/>
                    </div>
                </div>
            )            
        )            
    }

    return (
        <>       
            {
                (location.pathname.indexOf('category') > -1) && (
                    <Helmet>
                        <title>{((Object.keys(category).length > 0) ? (category?.['meta_title']) : '')}</title>
                        <meta property="og:description" content={((Object.keys(category).length > 0) ? (category?.['og_description']) : '')} />
                        <meta property="og:title" content={((Object.keys(category).length > 0) ? (category?.['og_title']) : '')} />
                        <meta property="og:url" content={`${window.location.origin}${((Object.keys(category).length > 0) ? (category?.['url']) : '')}`} />
                        <meta name="twitter:title" content={((Object.keys(category).length > 0) ? (category?.['og_title']) : '')} />
                        <meta name="twitter:description" content={((Object.keys(category).length > 0) ? (category?.['og_description']) : '')} />
                        <meta name="title" content={((Object.keys(category).length > 0) ? (category?.['meta_title']) : '')} />
                        <meta name="description" content={((Object.keys(category).length > 0) ? (category?.['meta_description']) : '')} />
                        <meta name="keywords" content={((Object.keys(category).length > 0) ? (category?.['keywords']) : '')} />
                    </Helmet>
                )
            }

            {
                (location.pathname.indexOf('search') > -1) && (
                    <Helmet>
                        <title>{`Search | ${helpers.WEBSITE_NAME_NOSPACE}`}</title>
                        <meta property="og:description" content={`${search_name} | ${helpers.WEBSITE_NAME_NOSPACE}`} />
                        <meta property="og:title" content={`Search | ${helpers.WEBSITE_NAME_NOSPACE}`} />
                        <meta property="og:url" content={`${window.location.href}`} />
                        <meta name="twitter:title" content={`Search | ${helpers.WEBSITE_NAME_NOSPACE}`} />
                        <meta name="twitter:description" content={`${search_name} | ${helpers.WEBSITE_NAME_NOSPACE}`} />
                        <meta name="title" content={`Search | ${helpers.WEBSITE_NAME_NOSPACE}`} />
                    </Helmet>
                )
            }
            
            <div className='breadcrumb-outer'>
                <div className='custom-container'>
                    <div className='breadcrumb-bx'>
                        <Link className='breadcrumb-link breadcrumb-back' to="/">Home<i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                        <Link className='breadcrumb-link breadcrumb-active' to="/">{((location.pathname.indexOf('search') > -1) ? search_name : ((location.pathname.indexOf('category') > -1) ? category?.cat_name : ''))}</Link>
                    </div>
                </div>
            </div>

            <div className='custom-container'>
                <div className="newscatbox-outer category-outer">

                <section className='newscatmainbox'>

                    <div className='newssidebx'>
                        <Squarebanner />

                        <div className='website-page-links'>
                            <Link to="/" className='wp-link'><ion-icon name="home-outline"></ion-icon>Home</Link>
                            <Link to="/about-us" className='wp-link'><ion-icon name="document-text-outline"></ion-icon>About Us</Link>
                            <Link to="/privacy-policy" className='wp-link'><ion-icon name="document-text-outline"></ion-icon>Privacy Policy</Link>
                            <Link to="/advertisement" className='wp-link'><ion-icon name="bookmarks-outline"></ion-icon>Advertisement</Link>
                            <Link to="/contact-us" className='wp-link'><ion-icon name="call-outline"></ion-icon>Contact Us</Link>
                            {/* <Link to="/contact-us" className='wp-link'><ion-icon name="mail-outline"></ion-icon>Feedback</Link> */} 
                        </div>

                        <div className='common-store-links'>
                            <Socialicon/>
                            <Storelink />
                        </div>

                        {/* <Fbwidgets /> */}
                        <Verticalbanner />                        
                    </div>

                    
                    <div className="newscatbox">
                        {sections.map(renderNews)}
                        
                        {loading && (
                            <div className='cat-news-list skeletonmainbox'>
                                <div className="cnl-item">
                                    <div className="cnl-item-img"></div>
                                    <div className="cnl-item-text">
                                        <div className="cnl-item-text-hed">
                                            <div className="loader-design ld3"></div>
                                            <div className="loader-design ld3"></div>
                                            <div className="loader-design ld3"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="cnl-item">
                                    <div className="cnl-item-img"></div>
                                    <div className="cnl-item-text">
                                        <div className="cnl-item-text-hed">
                                            <div className="loader-design ld3"></div>
                                            <div className="loader-design ld3"></div>
                                            <div className="loader-design ld3"></div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="cnl-item">
                                    <div className="cnl-item-img"></div>
                                    <div className="cnl-item-text">
                                        <div className="cnl-item-text-hed">
                                            <div className="loader-design ld3"></div>
                                            <div className="loader-design ld3"></div>
                                            <div className="loader-design ld3"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="cnl-item">
                                    <div className="cnl-item-img"></div>
                                    <div className="cnl-item-text">
                                        <div className="cnl-item-text-hed">
                                            <div className="loader-design ld3"></div>
                                            <div className="loader-design ld3"></div>
                                            <div className="loader-design ld3"></div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="cnl-item">
                                    <div className="cnl-item-img"></div>
                                    <div className="cnl-item-text">
                                        <div className="cnl-item-text-hed">
                                            <div className="loader-design ld3"></div>
                                            <div className="loader-design ld3"></div>
                                            <div className="loader-design ld3"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="cnl-item">
                                    <div className="cnl-item-img"></div>
                                    <div className="cnl-item-text">
                                        <div className="cnl-item-text-hed">
                                            <div className="loader-design ld3"></div>
                                            <div className="loader-design ld3"></div>
                                            <div className="loader-design ld3"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </section>

                </div>
            </div>
        </>
    );
}

export default Category;


Category.propTypes = {
    pagetitle: PropTypes.string.isRequired,
}

Category.defaultProps = {
    pagetitle: 'મોરબી',
}