import React , { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import helpers from './utils';


import Loader from "./Components/Loader";
import Header from "./Components/Header";
import Sidemenu from "./Components/Sidemenu";
import Newscatbox from "./Components/Newscatbox";
import Category from "./Components/Category";
// import Search from "./Components/Search";
import Detail from "./Components/Detail";
import Aboutus from "./Components/Aboutus";
import Privacypolicy from "./Components/Privacypolicy";
// import Advertisement from "./Components/Advertisement";
import Contactus from "./Components/Contactus";
import Footer from "./Components/Footer";

import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {

  return (
    <>
      <Helmet>
        <title>Vibes Of India - News, News Today, Breaking News, News in Hindi, News Hindi Latest, Entertainment News, News Latest Hindi, English News</title>
        <meta name="copyright" content={`Copyright ${helpers.WEBSITE_NAME}, Inc. All rights reserved.`} />
        <meta name="referrer" content="default" />
        <meta property="og:site_name" content={helpers.WEBSITE_NAME} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Discover authentic and accurate news of India at Vibes of India. Stay informed with trusted updates tailored to your interests. Read Now." />
        <meta property="og:title" content="Vibes Of India - News, News Today, Breaking News, News in Hindi, News Hindi Latest, Entertainment News, News Latest Hindi, English News" />
        
        
        <meta property="og:image" itemprop="image" content={helpers.OG_IMAGE} />
        <meta property="og:image:width" content="1133" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:url" content={helpers.OG_IMAGE} />
        <meta property="og:image:secure_url" content={helpers.OG_IMAGE} />
        <meta property="og:url" content={window.location.origin} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@VibesOfIndia" />
        <meta name="twitter:creator" content="VibesOfIndia" />
        <meta name="twitter:title" content="Vibes Of India - News, News Today, Breaking News, News in Hindi, News Hindi Latest, Entertainment News, News Latest Hindi, English News" />
        <meta name="twitter:description" content="Discover authentic and accurate news of India at Vibes of India. Stay informed with trusted updates tailored to your interests. Read Now." />
        <meta name="twitter:image" content={helpers.OG_IMAGE} />
        <meta name="title" content="Vibes Of India - News, News Today, Breaking News, News in Hindi, News Hindi Latest, Entertainment News, News Latest Hindi, English News" />
        <meta name="description" content="Discover authentic and accurate news of India at Vibes of India. Stay informed with trusted updates tailored to your interests. Read Now." />
        <meta name="keywords" content="Latest Gujarati News, Breaking News in Gujarati, Gujarat Samachar, ગુજરાતી સમાચાર, Gujarati News Live, Gujarati News Channel, Gujarati News Today, National Gujarati News, International Gujarati News, Sports Gujarati News, Exclusive Gujarati News, Coronavirus Gujarati News, Entertainment Gujarati News, Business Gujarati News, Technology Gujarati News, Automobile Gujarati News, Elections 2022 Gujarati News, Viral Social News in Gujarati, Indian Politics News in Gujarati, Gujarati News Headlines, World News In Gujarati, Cricket News In Gujarati"></meta>
      </Helmet>
      <BrowserRouter>
        <Loader/>
        <Header/>
        <Sidemenu/>
          <Routes>
            <Route exact="true" path="/" element={<Newscatbox />} />
            <Route exact="true" path="/category" element={<Category />} />
            <Route exact="true" path="/category/:name" element={<Category />} />            
            <Route exact="true" path="/search/:search_name" element={<Category pagetitle="Showing 41 news of રાજ્યસભા"/>} />
            {/* <Route exact="true" path="/search" element={<Search />} /> */}
            <Route exact="true" path="/detail" element={<Detail />} />
            <Route exact="true" path="/:year/:month/:date/:news_name" element={<Detail />} />
            <Route exact="true" path="/about-us" element={<Aboutus />} />
            <Route exact="true" path="/privacy-policy" element={<Privacypolicy />} />
            <Route exact="true" path="/contact-us" element={<Contactus />} />
            <Route exact="true" path="/advertisement" element={<Contactus pagetitle="Advertisement Inquiry" />} />
          </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;