import React, { useState, useEffect } from 'react';
import {Link, useNavigate, useParams, useLocation} from "react-router-dom";
import { Helmet } from 'react-helmet';
import helpers from './../utils';

// Css Link
import '../css/detail.css' 

// Images Common File
import Img from '../Components/Img';

// Design File
import Horizontalbanner from "../Components/Adsection/Horizontalbanner";
import Squarebanner from "../Components/Adsection/Squarebanner";
import Verticalbanner from "../Components/Adsection/Verticalbanner";
import Horizontalbigbanner from "../Components/Adsection/Horizontalbigbanner";
import Trendingwidgets from "../Components/Widgets/Trendingwidgets";
import Socialicon from "../Components/Socialicon";
import Storelink from '../Components/Storelink'

function Detail() {
    const location = useLocation();
    const navigate = useNavigate();
    const base_url = window.location.origin;

    const { year, month, date, news_name } = useParams();
    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [facebookUrl, setFacebookUrl] = useState('');
    const [twitterUrl, setTwitterUrl] = useState('');
    const [whatsappUrl, setWhatsappUrl] = useState('');
    const [linkedinUrl, setLinkedinUrl] = useState('');
    const [relatedNewsDetail, setRelatedNewsDetail] = useState([]);

    useEffect(() => {
        setNewsData([]);
        setLoading(true);
        const newsDetailGet = async () => {
            try {
                const news_response = await helpers.apiCall(helpers.API_BASE_URL+'news_detail',{news_id_url:news_name});
                if (news_response != '') {                    
                    if(Object.keys(news_response?.news).length > 0)
                    {
                        setNewsData(news_response?.news);
                    }
                    else
                    {
                        setNewsData([]);
                        navigate('/');
                    }
                }
                else
                {
                    setNewsData([]);
                    navigate('/');
                }
            } catch (error) {
                setNewsData([]);
                navigate('/');
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        }
        newsDetailGet();
    },[year, month, date, news_name]);

    useEffect(() => {
        if(Object.keys(newsData).length > 0)
        {
            setFacebookUrl("https://www.facebook.com/sharer/sharer.php?u="+base_url+newsData?.news_link2);
            setTwitterUrl("https://twitter.com/share?text="+newsData?.link_title+"&url="+base_url+newsData?.news_link2);
            setWhatsappUrl("https://api.whatsapp.com/send?text="+newsData?.link_title+". "+base_url+newsData?.news_link2);
            setLinkedinUrl("https://www.linkedin.com/shareArticle?mini=true&url="+base_url+newsData?.news_link2+"&title="+newsData?.link_title+"&summary="+newsData?.sort_description+"&source="+helpers.WEBSITE_NAME_NOSPACE);
            relatedNewsGet();
        }
    },[newsData]);

    const relatedNewsGet = async () => {
        try {
            const related_news_response = await helpers.apiCall(helpers.API_BASE_URL+'get-trending',{page:1,news_id:newsData?.id});
            if (related_news_response != '') {
                setRelatedNewsDetail(related_news_response.news);
            }
            else
            {
                setRelatedNewsDetail([]);
            }
        } catch (error) {
            setRelatedNewsDetail([]);
            console.error('Error fetching data:', error);
        }
    }

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(base_url+newsData?.news_link2);
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }
    };

    const imageSet = () => {
        if(Object.keys(newsData).length > 0)
        {
            if(newsData?.media_type == 'v')
            {
                if(newsData?.video_type == 'v')
                {
                    return (<video controls poster={newsData?.original_image}>
                        <source src={newsData?.video_url} type="video/webm"/>
                    </video>);
                }
                else if(newsData?.video_type == 'y')
                {
                    return (<iframe src={`${newsData?.video_youtube_url}?showinfo=0`} class="clearfix video_div"></iframe>);
                }
            }
            else if(newsData?.media_type == 'a')
            {
                return (<audio src={newsData?.audio_url} controls/>);
            }
            else
            {
                return (<img src={newsData?.original_image} alt="detail news" loading="lazy" />);
            }
        }
        else
        {
            return '';
        }
    }

    return (
        <>
        <Helmet>
            <title>{((Object.keys(newsData).length > 0) ? (newsData?.['meta_title']) : '')}</title>
            <meta property="og:description" content={((Object.keys(newsData).length > 0) ? (newsData?.['og_description']) : '')} />
            <meta property="og:title" content={((Object.keys(newsData).length > 0) ? (newsData?.['og_title']) : '')} />
            <meta property="og:url" content={`${window.location.origin}${((Object.keys(newsData).length > 0) ? (newsData?.['news_link2']) : '')}`} />
            <meta property="og:image" itemprop="image" content={((Object.keys(newsData).length > 0) ? (newsData?.['og_image']) : '')} />
            <meta property="og:image:url" content={((Object.keys(newsData).length > 0) ? (newsData?.['og_image']) : '')} />
            <meta property="og:image:secure_url" content={((Object.keys(newsData).length > 0) ? (newsData?.['og_image']) : '')} />
            <meta name="twitter:image" content={((Object.keys(newsData).length > 0) ? (newsData?.['og_image']) : '')} />
            <meta name="twitter:title" content={((Object.keys(newsData).length > 0) ? (newsData?.['og_title']) : '')} />
            <meta name="twitter:description" content={((Object.keys(newsData).length > 0) ? (newsData?.['og_description']) : '')} />
            <meta name="title" content={((Object.keys(newsData).length > 0) ? (newsData?.['meta_title']) : '')} />
            <meta name="description" content={((Object.keys(newsData).length > 0) ? (newsData?.['meta_description']) : '')} />
            <meta name="keywords" content={((Object.keys(newsData).length > 0) ? (newsData?.['keywords']) : '')} />
        </Helmet>
        <div className='breadcrumb-outer'>
            <div className='custom-container'>   
                <div className='breadcrumb-bx'>
                    <Link className='breadcrumb-link breadcrumb-back' to="/">Home<i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                    <Link className='breadcrumb-link' to={((newsData?.category_url) ? (newsData?.category_url) : 'javascript:void(0);' )}>{newsData?.category_name}<i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                    <Link className='breadcrumb-link breadcrumb-active'>{newsData?.title}</Link>
                </div>
            </div>
        </div>
        
        <div className="newscatbox-outer">
            <div className='custom-container'>
                {
                    (Object.keys(newsData).length > 0) && (
                        <div className='bnr-da-cat'>
                            <Horizontalbanner />
                        </div>
                    )
                }

                <div className='newscatmainbox newsdetailbox'>
                    <div className="detail-news-outer">

                        <div className="detail-news-heading">{((Object.keys(newsData).length > 0) ? (newsData?.['highlight_title']+' '+newsData?.['title']) : '')}</div>

                        <div className="meta-social-box">
                            <div className="meta-author">
                                <img className="meta-author-img" src={Img.metaimg} alt="author name" loading="lazy" />
                                <div className="meta-author-data">
                                    <div className="meta-data-name">Vibes Of India</div>
                                    <div className="meta-data-time">{newsData?.news_time}</div>
                                </div>
                            </div>

                            <div className="social_share-bx">
                                <span className="ssb-lable">Share :</span>
                                <Link to={facebookUrl} className="ssb-icon fb"><i className="fa fa-facebook" aria-hidden="true"></i></Link>
                                <Link to={twitterUrl} className="ssb-icon tw"><i className="fa fa-twitter" aria-hidden="true"></i></Link>
                                <Link to={whatsappUrl} className="ssb-icon wa"><i className="fa fa-whatsapp" aria-hidden="true"></i></Link>
                                <Link to={linkedinUrl} className="ssb-icon li"><i className="fa fa-linkedin" aria-hidden="true"></i></Link>
                                <Link to='javascript:void(0);' onClick={copyToClipboard} className="ssb-icon cm"><i className="fa fa-link" aria-hidden="true"></i></Link>
                            </div>
                        </div>

                        <div className='detail-news-ivio-bx'>
                            {imageSet()}
                            {/* <img src={Img.demo_img1} alt="detail news" /> */}
                            {/*<video controls poster={Img.demo_videoposter1}>
                                <source src='https://www.youtube.com/embed/ODLiJ2_CGXI' type="video/webm"/>
                            </video>*/}
                            {/* <audio src="" controls/> */}
                        </div>
                        <div className='ivio-tag'>{((Object.keys(newsData).length > 0) ? (newsData?.['highlight_title']+' '+newsData?.['title']) : '')}</div>


                        <div className='detail-news-text-bx' dangerouslySetInnerHTML={{ __html: newsData?.description_detail }}>
                        </div>

                        <div className='detail-news-tags'>
                            {
                                newsData?.tags_detail && newsData?.tags_detail?.length > 0 && (
                                    newsData?.tags_detail.map((item,index) => (
                                        <span key={index}>{item}</span>
                                    ))
                                )
                            }
                        </div>

                        {
                            (Object.keys(newsData).length > 0) && (
                                <div className='bnr-da-cat'>
                                    <Horizontalbigbanner />
                                </div>
                            )
                        }

                        
                        <div className='detail-news-related'>
                            <div className='common-heading'>Follow Us On</div>
                            <div className="social-fuo">
                                <Socialicon/>
                            </div>
                        </div>

                        <div className='detail-news-related'>
                            <div className='common-heading'>Related News</div>
                            {
                                relatedNewsDetail && (relatedNewsDetail.length > 0) && (
                                    <div className="cat-news-list">
                                    {
                                        relatedNewsDetail.map((item,index) => (
                                            <div className="cnl-item" key={index}>
                                                <Link to={((item.news_link2) ? item.news_link2 : 'javascript:void(0);' )} className="cnl-item-img">
                                                    <img className="cnl-item-img-bg" src={item.image} alt="" loading="lazy" />
                                                </Link>
                                                <Link className="cnl-item-text" to={((item.news_link2) ? item.news_link2 : 'javascript:void(0);' )}>
                                                    <div className="cnl-item-text-hed">{item.highlight_title+' '+item.title}</div>
                                                </Link>
                                            </div>
                                        ))
                                    }
                                    </div>
                                )
                            }
                        </div>

                        {
                            (Object.keys(newsData).length > 0) && (
                                <div className='bnr-da-cat bdc-odd'>
                                    <Horizontalbanner/>
                                </div>
                            )
                        }
            
                    </div>


                    <div className='newssidebx'>
                        {
                            (Object.keys(newsData).length > 0) && (
                                <>
                                    <Squarebanner />
                                    <Trendingwidgets />
                                    <div className='common-store-links'>
                                        <Socialicon/>
                                        <Storelink />
                                    </div>
                                    <Verticalbanner />
                                </>
                            )
                        }                        
                    </div>
                </div>
            </div>
        </div>

            {loading && (
                <div className="newscatbox-outer">
                    <div className='custom-container'>
                        
                        <div className='bnr-da-cat'>
                            <div className="bnr-da-img">
                                <img className="desk-img width-fit-content" src={Img.demo_ad_img6} alt="" loading="lazy" />
                                <img className="mobile-img" src={Img.demo_ad_img7} alt="" loading="lazy" />
                            </div>
                        </div>

                        <div className='newscatmainbox newsdetailbox skeletonmainbox'>
                            <div className="detail-news-outer">

                                <div className="detail-news-heading">
                                    <div className="loader-design ld1"></div>
                                    <div className="loader-design ld1"></div>
                                </div>

                                <div className="meta-social-box">
                                    <div className="meta-author">
                                        <div className="meta-author-img loader-design"></div>
                                        <div className="meta-author-data">
                                            <div className="loader-design ld4"></div>
                                            <div className="loader-design ld4"></div>
                                        </div>
                                    </div>

                                    <div className="social_share-bx">
                                        <div className="ssb-icon loader-design ld-no-img"></div>
                                        <div className="ssb-icon loader-design ld-no-img"></div>
                                        <div className="ssb-icon loader-design ld-no-img"></div>
                                        <div className="ssb-icon loader-design ld-no-img"></div>
                                        <div className="ssb-icon loader-design ld-no-img"></div>
                                    </div>
                                </div>

                                <div className='detail-news-ivio-bx'></div>
                                <div className='ivio-tag'><div className="loader-design ld5"></div></div>


                                <div className='detail-news-text-bx'>
                                    <div className="loader-design ld3"></div>
                                    <div className="loader-design ld3"></div>
                                    <div className="loader-design ld3"></div>
                                    <div className="loader-design ld3"></div>
                                    <div className="loader-design ld3"></div>
                                </div>

                            </div>


                            <div className='newssidebx'>
                                <div className='loader-bx loader-design'></div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Detail;