import axios from 'axios';

// const SITE_BASE_URL = "http://morbiupdate.pm";
const SITE_BASE_URL = "https://vibesbackend.pmcommu.in";
//const SITE_BASE_URL = "http://vibesbackend.pm";
const API_BASE_URL = SITE_BASE_URL+"/api/?v=1&device-type=3&service=";
const API_XHR_BASE_URL = SITE_BASE_URL+"/xhr/";
const OG_IMAGE = SITE_BASE_URL+"/img/muogimg.jpg";
const WEBSITE_NAME_NOSPACE = 'VibesOfIndia';
const WEBSITE_NAME = "Vibes Of India";

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const apiCall = async (url,option = {}) => {
  try {
    var form_data = new FormData();
    form_data.append('ip_address','192.168.1.1');
    form_data.append('device_id','123456');
    form_data.append('lang','gj');
    if(Object.keys(option).length > 0)
    {
      Object.keys(option).map((key) => (        
        form_data.append(key,option[key])
      ));     
    }
    const requestOptions = {
        method: 'POST',        
        body: form_data
    };

    return axios.post(url,form_data)
    .then(response => {
      if(response.data.error == '0' || response.data.error == 0)
      {
        return response.data;
      }
      else
      {
        return "";
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      return "";
    });
  } catch (error) {
    console.error('Error fetching data:', error);
    return "";
  }
};

const apiCallWithErrorMessage = async (url,option = {}) => {
  try {
    var form_data = new FormData();
    form_data.append('ip_address','192.168.1.1');
    form_data.append('device_id','123456');
    form_data.append('lang','gj');
    if(Object.keys(option).length > 0)
    {
      Object.keys(option).map((key) => (        
        form_data.append(key,option[key])
      ));     
    }

    return axios.post(url,form_data,{ headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => {
      if(response.data.error == '0' || response.data.error == 0)
      {
        return response.data;
      }
      else
      {
        return response.data;
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      return "";
    });
  } catch (error) {
    console.error('Error fetching data:', error);
    return "";
  }
};

function loadAds(ad_size,class_name=""){
  try {
    const form_data = new FormData();
    form_data.append('ip_address','192.168.1.1');
    form_data.append('device_id','123456');
    form_data.append('lang','gj');
    form_data.append('ad_size',ad_size);
    form_data.append('class_name',class_name);

    return axios.post(API_BASE_URL+'get-premiums',form_data)
    .then(response => {
      if(response.data.error == '0' || response.data.error == 0)
      {
        return response.data;
      }
      else
      {
        return "";
      }
    })
    .catch(error => {
      return "";
      console.error('Error fetching data:', error);
    });
  } catch (error) {
    console.error('Error fetching data:', error);
    return "";
  }
}

function getCategoryNews(category_id,limit,pageId='') {
  try {
    const form_data = new FormData();
    form_data.append('ip_address','192.168.1.1');
    form_data.append('device_id','123456');
    form_data.append('lang','gj');
    form_data.append('page',1);
    form_data.append('limit',limit);
    form_data.append('category',category_id);
    form_data.append('page_id',pageId);

    return axios.post(API_BASE_URL+'get-news',form_data)
    .then(response => {
      if(response.data.error == '0' || response.data.error == 0)
      {
        return response.data;
      }
      else
      {
        return "";
      }
    })
    .catch(error => {
      return "";
      console.error('Error fetching data:', error);
    });    
  } catch (error) {
    console.error('Error fetching data:', error);
    return "";
  }
}

function loadTrendingNews(limit = 7) {
  try {
    const form_data = new FormData();
    form_data.append('ip_address','192.168.1.1');
    form_data.append('device_id','123456');
    form_data.append('lang','gj');
    form_data.append('page',1);
    form_data.append('limit',limit);
    form_data.append('trending',1);

    return axios.post(API_BASE_URL+'get-news',form_data)
    .then(response => {
      if(response.data.error == '0' || response.data.error == 0)
      {
        return response.data;
      }
      else
      {
        return "";
      }
    })
    .catch(error => {
      return "";
      console.error('Error fetching data:', error);
    });    
  } catch (error) {
    console.error('Error fetching data:', error);
    return "";
  }
}

const loadAdsWithToggleBottom = async (ad_size,class_name="") => {
  try {
    const form_data = new FormData();
    form_data.append('ip_address','192.168.1.1');
    form_data.append('device_id','123456');
    form_data.append('lang','gj');
    form_data.append('ad_size',ad_size);
    form_data.append('class_name',class_name);
    const requestOptions = {
        method: 'POST',        
        body: form_data
    };

    return axios.post(API_BASE_URL+'get-premiums',form_data)
    .then(response => {
      if(response.data.error == '0' || response.data.error == 0)
      {
        return response.data;
      }
      else
      {
        return "";
      }
    })
    .catch(error => {
      return "";
      console.error('Error fetching data:', error);
    });    
  } catch (error) {
    console.error('Error fetching data:', error);
    return "";
  }
};

const helpers = {
    SITE_BASE_URL,
    API_BASE_URL,
    API_XHR_BASE_URL,
    WEBSITE_NAME_NOSPACE,
    WEBSITE_NAME,
    OG_IMAGE,
    loadAdsWithToggleBottom,
    apiCall,
    loadAds,
    capitalize,
    getCategoryNews,
    loadTrendingNews,
    apiCallWithErrorMessage,
}


export default helpers;