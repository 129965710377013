import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import helpers from './../utils';

function Aboutus() {
    return (
        <>
            <Helmet>
                <title>About Us | Morbi Update</title>
                <meta property="og:description" content="Morbi Update is a 24/7 Satellite News Channel that keeps morbiupdate in the News." />
                <meta property="og:title" content="About Us | Morbi Update" />
                <meta property="og:url" content={`${window.location.origin}/privacy-policy`} />
                <meta name="twitter:title" content="About Us | Morbi Update" />
                <meta name="twitter:description" content="Morbi Update is a 24/7 Satellite News Channel that keeps morbiupdate in the News." />
                <meta name="title" content="About Us | Morbi Update" />
                <meta name="description" content="Morbi Update is a 24/7 Satellite News Channel that keeps morbiupdate in the News." />
                <meta name="keywords" content="About Morbi Update News Channel" />
            </Helmet>
            <div className='breadcrumb-outer'>
                <div className='custom-container'>
                    <div className='breadcrumb-bx'>
                        <Link className='breadcrumb-link breadcrumb-back' to="/">Home<i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                        <Link className='breadcrumb-link breadcrumb-active' to="/">About Us</Link>
                    </div>
                </div>
            </div>

            <div className='custom-container'>
                <div className='page-heading'>About Us</div>
                <div className='static-content'>
                <p>
                We are making all efforts to provide quality journalism. We have no secondary business interests except journalism. We remain limited, not in our vision, but our resources. We are still doing our best. You can help us by sharing our articles and giving us regular feedback.
                </p>
                <p>
Virago Media Private Limited is a Gujarat-based multimedia digital news platform. The multimedia, the multilingual digital-only news platform of Gujarat is available in Gujarati, English, and Hindi.  Virago Media Pvt Ltd is the parent company of  Vibes of India that disseminates information through various multimedia platforms.
</p>
<p>
Editor – Naresh Makwana

naresh@vibesofindia.com
</p>
<p>
For marketing and collaboration enquiries please contact renish@vibesofindia.com

</p>
                </div>
            </div>
        </>
    );
}

export default Aboutus;