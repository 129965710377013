// Images Common File
import Img from '../Components/Img';

function Storelink() {

    return (
        <>
            <div className='csl-lable'>Download App now</div>
            <div className="csl-store-links">
                <a href="https://play.google.com/store/apps/details?id=com.voiapp.app&hl=en_IN&gl=US" className="cs-link"><img src={Img.playstore} alt="PlayStore" className="img-responsive" /></a>
                <a href="https://apps.apple.com/in/app/voi-vibes-of-india/id1589793514" className="cs-link"><img src={Img.appstore} alt="Appstore" className="img-responsive" /></a>
            </div>
        </>
    );
}

export default Storelink;